export default [
  {
    title: 'Tags',
    icon: 'mdi-tag-multiple',
    to: 'TagsAttributes'
  },
  {
    title: 'Collection',
    icon: 'mdi-rhombus-split',
    to: 'CollectionAttribute'
  },
  {
    title: 'Group Category',
    icon: 'mdi-gamepad-circle-outline',
    to: 'GroupCategoryAttribute'
  },
  {
    title: 'Color',
    icon: 'mdi-palette',
    to: 'ColorAttributes'
  },
  {
    title: 'Category',
    icon: 'mdi-tshirt-crew',
    to: 'CategoryAttributes'
  },
  {
    title: 'Brand',
    icon: 'mdi-storefront',
    to: 'BrandAttributes'
  },
  {
    title: 'Size',
    icon: 'mdi-size-xl',
    to: 'SizeAttributes'
  },
  {
    title: 'MixAndMatch',
    icon: 'mdi-cash-multiple',
    to: 'MixAndMacthAttributes'
  },
  {
    title: 'ForeignCurrency',
    icon: 'mdi-cash-multiple',
    to: 'ForeignCurrencyAttributes'
  },
  {
    title: 'Sale Tags',
    icon: 'mdi-tag-multiple',
    to: 'SaleTags'
  }
]
